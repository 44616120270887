import { ENV_OPTIONS } from '@up/shared/injection-tokens';
import { EnvironmentOptions } from '@up/shared/interfaces';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WebGiftCardPreviewRoutingModule } from './web-gift-card-preview-routing.module';
import { GiftCardPreviewComponent } from './components/gift-card-preview/gift-card-preview.component';
import { SafeHtmlPipe } from './pipes/safeHtmlPipe';
import { ErrorComponent } from './components/error/error.component';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    GiftCardPreviewComponent,
    ErrorComponent,
    SafeHtmlPipe],
  imports: [
    CommonModule,
    WebGiftCardPreviewRoutingModule,
    NgxLoadingModule.forRoot({}),
    TranslateModule.forRoot()
  ],
  exports: [
    ErrorComponent
  ]
})
export class WebGiftCardPreviewModule {
  static forRoot(
    environmentOptions: EnvironmentOptions
  ): ModuleWithProviders<WebGiftCardPreviewModule> {
    return {
      ngModule: WebGiftCardPreviewModule,
      providers: [
        {
          provide: ENV_OPTIONS,
          useValue: environmentOptions
        }
      ]
    };
  }
}
