import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GiftCardDto } from '@up/data';
import { ENV_OPTIONS } from '@up/shared/injection-tokens';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GiftCardPreviewService {
  readonly API_KEY = 'gift-card-preview';
  readonly API_URL = `${this.environmentOptions.apiUrl}/${this.API_KEY}`;

  constructor(
    @Inject(ENV_OPTIONS) private environmentOptions: any,
    private http: HttpClient
  ) { }

  public getByGiftCardLinkAndCvv(code: string, cvv: string): Observable<GiftCardDto> {
    const url = `${this.API_URL}`;
    return this.http.get<GiftCardDto>(
      url + `/getByGiftCardLinkAndCvv?` +
      `&filter=giftCard.giftCardLink||$eq||${code}&cvv=${cvv}`)
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return observableThrowError(res.error || 'Server error');
  }
}
