<div id="root">
  <div class="fixed-background">
    <main>
      <div class="container">
        <div class="row h-100">
          <div class="col-12 col-md-10 my-auto">
            <div class="auth-card shadow-lg rounded">
              <div class="position-relative image-side ">
                <p class=" text-white h2">ADVANCED SOFTWARE</p>
                <p class="white mb-0">Made simple</p>
              </div>
              <div class="form-side">
                <a routerLink="/">
                  <span class="logo-single"></span>
                </a>
                <h6 class="mb-4">The gift card you requested could not be found or doesn't have a valid configuration.</h6>
                <p class="text-muted text-small mb-0">
                  Error code
                </p>
                <p class="display-1 font-weight-bold mb-5">404</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
