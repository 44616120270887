import { Component, OnDestroy, OnInit } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'up-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-gift-card-preview';
  observer: ResizeObserver = null;
  ngOnInit(): void {
    const obs = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        window.parent.postMessage(
          { contentHeight: document.body.clientHeight },
          '*'
        );
      });
    });
    obs.observe(document.body);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
