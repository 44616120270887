import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GiftCardPreviewComponent } from './components/gift-card-preview/gift-card-preview.component';

@NgModule({
  imports: [RouterModule.forChild([{
    path: '',
    pathMatch: 'full',
    component: GiftCardPreviewComponent
  }])],
  exports: [RouterModule]
})
export class WebGiftCardPreviewRoutingModule { }
