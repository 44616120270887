import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GiftCardEmailTemplateDto } from '@up/data';
import { ENV_OPTIONS } from '@up/shared/injection-tokens';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GiftCardEmailTemplateService {
  readonly API_KEY = 'gift-card-email-template';
  readonly API_URL = `${this.environmentOptions.apiUrl}/${this.API_KEY}`;

  constructor(
    @Inject(ENV_OPTIONS) private environmentOptions: any,
    private http: HttpClient
  ) {}

  public get(id: string): Observable<GiftCardEmailTemplateDto> {
    const url = `${this.API_URL}`;
    return this.http.get<GiftCardEmailTemplateDto>(url + `/${id}`).pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return observableThrowError(res.error || 'Server error');
  }
}
