import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from '@up/web-gift-card-preview';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'view',
          loadChildren: () =>
            import('@up/web-gift-card-preview').then(
              (module) => module.WebGiftCardPreviewModule
            )
        },
        {
          path: '**',
          pathMatch: 'full',
          redirectTo: 'error'
        },
        {
          path: 'error',
          pathMatch: 'full',
          component: ErrorComponent,
          data: { breadcrumb: 'Error' }
        }
      ],
      { initialNavigation: 'enabled', scrollPositionRestoration: 'enabled' }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
