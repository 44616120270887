<div id="formContent">
    <div class="text-center mt-4 no-print">
        <button
            class="btn btn-action border-btnModBorder bg-btnModColor text-sm text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 w-40"
            (click)="saveAsPDF()">
            <svg class="h-6 w-8 transform text-gray-900" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64">
                <path fill="none" stroke="#020202" stroke-miterlimit="10" stroke-width="4" d="M50,39H18l-4-4V5a2,2,0,0,1,2-2H48a2,2,0,0,1,2,2Z" />
                <rect width="20" height="16" x="22" y="3" fill="none" stroke="#020202" stroke-miterlimit="10" stroke-width="4" />
                <rect width="20" height="12" x="22" y="27" fill="none" stroke="#020202" stroke-miterlimit="10" stroke-width="4" />
                <line x1="30" x2="30" y1="33" y2="39" fill="none" stroke="#020202" stroke-miterlimit="10" stroke-width="4" />
                <polyline fill="none" stroke="#010101" stroke-miterlimit="10" stroke-width="4" points="42 53 34 61 26 53" />
                <line x1="34" x2="34" y1="45" y2="61" fill="none" stroke="#020202" stroke-miterlimit="10" stroke-width="4" />
            </svg>
            Save as PDF
        </button>
    </div>

    <ngx-loading [show]="loading"></ngx-loading>
    <div [innerHTML]="displayedHTML | safeHtml"></div>
</div>