import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AgmCoreModule} from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { WebGiftCardPreviewModule } from '@up/web-gift-card-preview';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AgmCoreModule.forRoot({  apiKey: 'AIzaSyBeLQTBtfLfjMxOQabUB31R3VFdY3F9Oeg', libraries: ['places']}),
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    AppRoutingModule,
    WebGiftCardPreviewModule.forRoot({
      apiUrl: environment.apiUrl
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
