import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { GiftCardDto, PaymentDto } from '../../../../../data/src';
import { GiftCardPreviewService } from '../../services/gift-card-preview.service';
import { GiftCardEmailTemplateService } from '../../services/gift-card-email-template.service';

@Component({
  selector: 'up-gift-card-preview',
  templateUrl: './gift-card-preview.component.html',
  styleUrls: ['./gift-card-preview.component.css']
})
export class GiftCardPreviewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private giftCardPreviewService: GiftCardPreviewService,
    private giftCardEmailTemplateService: GiftCardEmailTemplateService
  ) {
    this.queryParams$ = this.route.queryParams.subscribe((params) => {
      this.code = params.code;
      this.cvv = params.cvv;
      this.templateId = params.templateId;
    });
  }

  queryParams$ = new Subscription();
  private giftCard: GiftCardDto;
  private payment: PaymentDto;
  private parser = new TranslateDefaultParser();

  public displayedHTML = '';
  public code: string;
  public cvv: string;
  public templateId: string;
  public templateHtml: string;
  public loading = true;

  title = 'web-gift-card-preview';

  parseStringMap(baseString: string, args): string {
    return this.parser.interpolate(baseString, args);
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    if (this.code) {
      await this.giftCardPreviewService
        .getByGiftCardLinkAndCvv(this.code, this.cvv)
        .toPromise()
        .then(
          async (gc) => {
            this.payment = gc.payments?.pop();
            this.giftCard = gc;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            this.router.navigate(['/error']);
            return;
          }
        );
    }

    if (this.templateId) {
      await this.giftCardEmailTemplateService
        .get(this.templateId)
        .toPromise()
        .then((emailTemplate) => {
          this.templateHtml = emailTemplate.templateHtml;
          if (!this.templateHtml) {
            console.log(
              `No TemplateHtml defined for template with id ${this.templateId}.`
            );
            this.router.navigate(['/error']);
            return;
          }
        });
    } else {
      this.templateHtml = this.giftCard?.giftCardType?.template?.templateHtml;
      if (!this.templateHtml) {
        console.log(
          'No TemplateHtml defined on the corresponding giftCardEmailTemplate.'
        );
        this.router.navigate(['/error']);
        return;
      }
    }

    const params = this.getHtmlParams();

    this.displayedHTML = this.parseStringMap(
      this.removeUnsupportedConstructs(this.templateHtml),
      { params: params }
    );
  }

  private getHtmlParams() {
    if (!this.giftCard.message) {
      this.removeMessageConstruct();
    }

    this.giftCard.message = this.giftCard.message ?? '';

    const digitalWalletLinks = {
      appleWallet: this.giftCard.appleWalletS3Link ?? '#',
      googleWallet: this.giftCard.googleWalletJwtLink ?? '#'
    };

    let itemAmount = this.getItemAmount();
    this.cleanupHtmlForBackofficeGiftCards();

    // UP-7524 - Giftcard - Gift Card Preview show actual Gift card balance rather than amount
    this.giftCard.amount = this.giftCard.balance;

    const params = {
      giftCard: this.giftCard,
      digitalWalletLinks,
      item: 'Gift Card',
      itemAmount,
      payment: this.payment
    };

    return params;
  }

  private removeMessageConstruct() {
    const messageConstruct = '{% if params.giftCard.message.* %}';
    this.templateHtml = this.templateHtml.replace(
      new RegExp(messageConstruct, 'g'),
      ''
    );
  }

  private cleanupHtmlForBackofficeGiftCards() {
    if (!this.payment?.data?.last4)
      this.templateHtml = this.templateHtml.replace(
        '{{ params.payment.data.last4 }}',
        ''
      );

    if (!this.payment?.merchantPaymentMethod?.paymentMethod?.processingFee)
      this.templateHtml = this.templateHtml.replace(
        new RegExp(
          '{{ ?params.payment.merchantPaymentMethod.paymentMethod.processingFee.* }}',
          'g'
        ),
        '0'
      );
  }

  private getItemAmount() {
    let itemAmount = this.payment?.authorizedAmount;

    if (this.payment?.paymentDonations?.length > 0) {
      for (const donation of this.payment.paymentDonations) {
        itemAmount -= donation.amount;
      }
    }
    // Deduct processing fee
    if (this.payment?.merchantPaymentMethod?.paymentMethod?.processingFee > 0) {
      itemAmount -= this.payment?.merchantPaymentMethod?.paymentMethod
        ?.processingFee;
    }
    return itemAmount;
  }

  private removeUnsupportedConstructs(html: string): string {
    const sendInBlueConstructs = '{% .*? %}|\\| ?floatformat: . ?';
    return html.replace(new RegExp(sendInBlueConstructs, 'g'), '');
  }

  saveAsPDF() {
    window.print();
  }
}
